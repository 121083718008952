/**
 * Caution: Consider this file when using NextJS or GatsbyJS
 *
 * You may delete this file and its occurrences from the project filesystem if you are using react-scripts
 */
import React from 'react';
import {Giving} from 'views';
import {FarewellMain} from 'layouts';
import WithLayout from 'WithLayout';
import DonationInfoModal from '../views/Giving/components/DonationInfoModal';

const GivingPage = ({location}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <WithLayout
        component={Giving}
        layout={FarewellMain}
        openModal={handleOpen}
        pageContext={{
          title: `Scholarship Fund | Make School.org`,
          socialTitle: 'Scholarship Fund | Make School.org',
          description:
            'Make School.org is a non-profit organization dedicated to creating avenues of upward mobility for students of all backgrounds, empowering them to contribute to society through science and technology innovation.',
          location,
        }}
      />
      <DonationInfoModal open={open} handleClose={handleClose} />
    </>
  );
};

export default GivingPage;
